import { Grid, ResponsiveText } from '@eventbrite/wagtail-components';
import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import CTABlock from '../../sharedComponents/cta-block/CTABlock';
import { CTA_BLOCK_LINK_VARIANT } from '../../sharedComponents/cta-block/utils';
import LazyloadImage from '../../sharedComponents/LazyloadImage';
import {
    BaseModuleProps,
    ITestimonialBlock,
    TImageBlockAlignment,
} from '../types';

interface ISingleTestimonialProps {
    testimonial: ITestimonialBlock;
    isContentFromContentful?: boolean;
}

const responsiveQuoteProps = {
    base: {
        fontSize: 32,
        lineHeight: 40,
    },
    medium: {
        fontSize: 24,
        lineHeight: 32,
    },
    small: {
        fontSize: 24,
        lineHeight: 32,
    },
};

const responsiveAttributionProps = {
    base: {
        fontSize: 18,
        lineHeight: 24,
    },
    medium: {
        fontSize: 14,
        lineHeight: 20,
    },
    small: {
        fontSize: 14,
        lineHeight: 20,
    },
};

export const SingleTestimonial: React.FC<
    ISingleTestimonialProps & BaseModuleProps
> = ({ testimonial, variant, isContentFromContentful }) => {
    const { image, quote, attribution, cta, alignment = 'left' } = testimonial;
    let imageAlignment = image.alignment;
    if (isContentFromContentful) {
        imageAlignment = alignment.toLowerCase() as TImageBlockAlignment;
    }
    const baseOptions = { variant };
    const theme = useTheme();
    const [textClass] = theme.generateStyles('text', baseOptions);
    const [backgroundClass] = theme.generateStyles('background', baseOptions);

    return (
        <section className={backgroundClass}>
            <Grid
                base={{
                    gap: 40,
                    columnCount: 2,
                }}
                small={{
                    columnCount: 1,
                }}
                className="single-testimonial__container module-landing-page__module-container-content"
                style={{
                    flexDirection:
                        imageAlignment === 'left' ? 'row' : 'row-reverse',
                }}
            >
                <div className="single-testimonial__image">
                    <LazyloadImage
                        image={image.imageUrl}
                        alt={image.altText}
                        style={{}}
                    />
                </div>
                <article className="single-testimonial__content">
                    <ResponsiveText
                        component="q"
                        {...responsiveQuoteProps}
                        className={`single-testimonial__quote ${textClass}`}
                    >
                        {quote}
                    </ResponsiveText>
                    <ResponsiveText
                        {...responsiveAttributionProps}
                        component="cite"
                        className={`single-testimonial__attribution ${textClass}`}
                    >
                        {attribution}
                    </ResponsiveText>
                    {cta?.url ? (
                        <CTABlock
                            url={cta.url}
                            callToAction={cta.callToAction || ''}
                            variant={CTA_BLOCK_LINK_VARIANT}
                            themeVariant={variant}
                        />
                    ) : null}
                </article>
            </Grid>
        </section>
    );
};
