import { Carousel } from '@eventbrite/eds-carousel';
import React from 'react';
import LazyloadImage from '../../sharedComponents/LazyloadImage';
import './testimonial.scss';

interface Testimonial {
    title: string;
    quote: string;
    attribution: string;
    icon: string;
    alt?: string;
}

const TestimonialComponent = (testimonial: Testimonial) => {
    const { title, quote, attribution, icon, alt } = testimonial;
    return (
        <div className="testimonial">
            <h2 className="eds-text-color--grey-900 eds-text-hm eds-text--center mktg-text-weight--heavy ">
                {title}
            </h2>
            <div
                className="eds-text-hs eds-text-weight--regular eds-text-color--ui-900 eds-l-pad-vert-4 "
                //eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: quote }}
            />
            <div
                className="eds-text-bm eds-text-color--grey-700 eds-l-pad-bot-6 eds-l-pad-hor-6 mtkg-link-parent"
                style={{ textAlign: 'center' }}
                //eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: attribution }}
            />
            <LazyloadImage
                image={icon}
                alt={alt || title}
                classes={'mktg-testimonial-logo eds-text--center'}
                style={{
                    display: 'block',
                    margin: '0 auto',
                    position: 'relative',
                    zIndex: 100,
                }}
            />
        </div>
    );
};

interface TestimonialProps {
    testimonials: Testimonial[];
    moduleClass: string;
}

const TestimonialsModule = ({
    testimonials,
    moduleClass,
}: TestimonialProps) => {
    const showBottomCarouselNav = testimonials.length > 1;
    return (
        <section
            className={`marketing-container__max-width eds-bg-color--white testimonial-module mktg-vert-p-100 ${moduleClass}`}
        >
            <Carousel
                alwaysShowSideNavigation
                alwaysShowBottomNavigation={showBottomCarouselNav}
            >
                {testimonials.map((testimonial, index) => (
                    <TestimonialComponent key={index} {...testimonial} />
                ))}
            </Carousel>
        </section>
    );
};
export default TestimonialsModule;
