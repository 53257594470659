import React from 'react';
import { BaseModuleProps, ITestimonialBlock } from '../types';
import { convertContentfulVariantToEnum } from '../utils';
import { MultipleTestimonial } from './MultipleTestimonials';
import { SingleTestimonial } from './SingleTestimonial';
import './testimonial.scss';

interface ITestimonialProps {
    moduleClass: string;
    headline: string;
    isContentFromContentful?: boolean;
    testimonialGroup: Array<ITestimonialBlock>;
}

const Testimonial: React.FC<ITestimonialProps & BaseModuleProps> = ({
    headline,
    testimonialGroup,
    variant,
    isContentFromContentful,
}) => {
    if (isContentFromContentful) {
        variant = convertContentfulVariantToEnum(variant);
    }
    if (testimonialGroup.length === 1) {
        return (
            <SingleTestimonial
                testimonial={testimonialGroup[0]}
                variant={variant}
                isContentFromContentful={isContentFromContentful}
            />
        );
    } else {
        return (
            <MultipleTestimonial
                headline={headline}
                testimonials={testimonialGroup}
                variant={variant}
                isContentFromContentful={isContentFromContentful}
            />
        );
    }
};

export default Testimonial;
